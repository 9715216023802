yaModules.search = (function () {

    let $searchButton = $('.ya-search__button');
    let $containerForm = $('.ya-search__form');

    function addEventListeners() {
        $searchButton.on('click', toggleSearchForm);
    }

    function toggleSearchForm() {
        $containerForm.toggleClass('ya-visible');
    }

    return {
        init() {
            if ($searchButton.length) {
                addEventListeners();
            }
        }
    }
}());
