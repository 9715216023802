yaModules.order = (function () {

    let $yaNouisliders = $('.ya-nouislider.ya-is-order');

    function initNouislider() {
        $('.ya-nouislider.ya-is-order').each(function() {
            let $container = $(this);
            let $nouislider = $container.find('.nouislider');
            let from = $nouislider.data('from');
            let to = $nouislider.data('to');
            let step = $nouislider.data('step');
            let startValue = +$nouislider.data('value');

            noUiSlider.create($nouislider[0], {
                start: [startValue],
                step: step,
                range: {
                    'min': from,
                    'max': to
                },
                format: wNumb({
                    decimals: 0
                })
            });

            var snapValues = [
                $container.find('.ya-nouislider__min')[0]
            ];

            $nouislider[0].noUiSlider.on('update', function( values, handle ) {
                snapValues[handle].value = values[handle];
            });

            snapValues.forEach(function(el) {
                $(el).on('change', function() {
                    let min = snapValues[0].value;

                    $nouislider[0].noUiSlider.set(min);
                });
            });
        });
    }

    return {
        init() {
            if ($yaNouisliders.length) {
                initNouislider();
            }
        }
    }
}());

