yaModules.listProducts = (function () {

    let $buttonShowDetails = $('.ya-show-details');
    let $buttonCloseDetails = $('.ya-list-products__details-close');
    let classDetailsVisible = 'ya-details-visible';
    let $buttonsChangeView = $('.ya-list-products__view-button');
    let viewClasses = '';
    let $containerListProducts = $('.ya-list-products');

    function addEventListeners() {
        if($buttonShowDetails.length) {
            $buttonShowDetails.on('click', showDetails);
            $buttonCloseDetails.on('click', closeDetails);
        }
        if($buttonsChangeView.length) {
            $buttonsChangeView.on('click', changeView);
        }

    }

    function showDetails() {
        $(this).parents('.ya-list-products__li').addClass(classDetailsVisible);
    }

    function closeDetails() {
        $(this).parents('.ya-list-products__li').removeClass(classDetailsVisible);
    }

    function changeView(ev) {
        let $button = $(ev.target).closest('.ya-list-products__view-button');
        if($button.hasClass('ya-active')) return;

        let viewClass = $button.data('view');

        $buttonsChangeView.removeClass('ya-active');
        $button.addClass('ya-active');

        $containerListProducts.removeClass(viewClasses);
        $containerListProducts.addClass(viewClass);
    }

    function getViewClasses() {
        if(!$buttonsChangeView.length) return;

        $buttonsChangeView.each(function() {
            let viewClass = $(this).data('view');
            if(viewClass) {
                viewClasses += viewClass + ' ';
            }
        });
    }

    return {
        init() {
            addEventListeners();
            getViewClasses();
        }
    }
}());
