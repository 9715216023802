yaModules.card = (function () {

    let $fotorama = $('.fotorama--card');

    function appendAngles() {
        setTimeout(() => {
            let $fotoramaThumb = $fotorama.find('.fotorama__thumb');
            let $fotoramaStage = $fotorama.find('.fotorama__stage');

            $fotoramaThumb.append('<div class="ya-angle ya-x ya-md">');
            $fotoramaThumb.append('<div class="ya-angle ya-y ya-md">');

            $fotoramaStage.append('<div class="ya-angle ya-x">');
            $fotoramaStage.append('<div class="ya-angle ya-y">');
        }, 0);
    }

    return {
        init() {
            if ($fotorama.length) {
                appendAngles();
            }
        }
    }
}());
