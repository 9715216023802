//yaModules.reg = (function () {
//
//    let $regSelect = $('.ya-reg-select');
//    let $fieldBlocks = $('.ya-form__fields-block');
//
//    function addEventListeners() {
//        $regSelect.on('change', showCurrentFields);
//    }
//
//    function showCurrentFields() {
//        let valueSelect = $(this).val();
//        let $currentFieldBlock = $fieldBlocks.filter('[data-type=' + valueSelect + ']');
//
//        $fieldBlocks.removeClass('ya-visible');
//        $currentFieldBlock.addClass('ya-visible');
//    }
//
//    return {
//        init() {
//            if ($regSelect.length) {
//                addEventListeners();
//            }
//        }
//    }
//}());
