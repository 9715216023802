yaModules.contacts = (function () {

    let idMap = 'ya-contacts-map';
    let $mapContainer = $('#' + idMap);

    function createMap() {
        let coords = $mapContainer.data('coords');
        let map = new MapYandex({
            mapContainer: idMap,
            center: coords,
            marker: coords,
            controls: false
        });
    }

    return {
        init() {
            if ($mapContainer.length) {
                createMap();
            }
        }
    }
}());
